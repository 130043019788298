import jQuery from "jquery";
var BODY_LOADED_CLASS = "loaded";
var MENU_OPEN_CLASS = "menu-open";
var BODY_TRANSISTION_CLASS = "transition";
var MINIMUM_TRANSITION_TIME = 500;
var SCROLLED_CLASS = "scrolled";
import PubSub from "pubsub-js";

(function () {
  if (typeof window.CustomEvent === "function") {
    return false;
  }

  function CustomEvent(event, params) {
    params = params || {
      bubbles: false,
      cancelable: false,
      detail: undefined,
    };
    var evt = document.createEvent("CustomEvent");
    evt.initCustomEvent(
      event,
      params.bubbles,
      params.cancelable,
      params.detail
    );
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;
  window.CustomEvent = CustomEvent;
})();

(function ($) {
  var body = $("body");
  var lastScrollY = window.scrollY;
  var lastWidth = 0;

  function attachLazyLoad() {
    if (typeof LazyLoad === "undefined") {
      return;
    }

    if (lazyLoadInstance) {
      lazyLoadInstance.update();
    } else {
      var lazyLoadInstance = new LazyLoad({
        elements_selector: ".lazy",
      });
    }
  }

  window.attachLazyLoad = attachLazyLoad;

  function attachVideos() {
    var lazyVideos = [].slice.call(document.querySelectorAll("video.lazy"));

    if ("IntersectionObserver" in window) {
      var lazyVideoObserver = new IntersectionObserver(function (
        entries,
        observer
      ) {
        entries.forEach(function (video) {
          if (video.isIntersecting) {
            for (var source in video.target.children) {
              var videoSource = video.target.children[source];
              if (
                typeof videoSource.tagName === "string" &&
                videoSource.tagName === "SOURCE"
              ) {
                videoSource.src = videoSource.dataset.src;
              }
            }

            video.target.load();
            video.target.classList.remove("lazy");
            lazyVideoObserver.unobserve(video.target);
          }
        });
      });

      lazyVideos.forEach(function (lazyVideo) {
        lazyVideoObserver.observe(lazyVideo);
      });
    }
  }

  function attachPageNavigation() {
    $("body").on("click", "a", function (e) {
      if (window.DISABLE_AJAX_NAVIGATION) {
        return;
      }

      var link = $(this);
      var href = $(this).attr("href");

      if (href && href.indexOf("mailto:") > -1) {
        return;
      }

      if (href && href.indexOf("tel:") > -1) {
        return;
      }

      if (
        link.hasClass("ignore--ps") ||
        link.parents(".ignore--ps").length > 0
      ) {
        return;
      }

      if (href && href.indexOf("#") !== -1) {
        return;
      }

      if (link.attr("target") === "_blank") {
        return;
      }

      if (link.data("screen-id")) {
        return;
      }

      if (href && href.indexOf(".") === -1 && href.indexOf("http") === -1) {
        e.preventDefault();
        e.stopPropagation();

        goToPage(href, link);
      }
    });
  }

  /**
   * Track a Pageview via gtag
   */
  function trackPageview(href) {
    if (typeof gtag !== "undefined" && body.data("ga")) {
      var path = href.replace(window.location.origin, "");

      gtag("config", body.data("ga"), { page_path: path });
    }
  }

  /**
   * Handles the actual update of the title and the push state tracker
   */
  function handleBrowserUpdate(href, link) {
    var title = document.title;

    if (link && link.attr("title")) {
      title = link.attr("title");
    }

    document.title = title;

    history.pushState(null, title, href);
    trackPageview(href);
  }

  /**
   * Handle going to a given href
   */
  function goToPage(href, link) {
    // track microseconds for loading
    var start = new Date().getTime();
    body.addClass(BODY_TRANSISTION_CLASS);
    body.removeClass("skr-negative");

    $("html").removeClass(MENU_OPEN_CLASS);
    $("html").removeClass("show-on-page");

    // take the user back to the top
    $("html, body").animate({
      scrollTop: 0,
    });

    handleBrowserUpdate(href, link);

    $(".ajax-body").load(href + " .ajax-body--inner", function (data) {
      attachVideos();
      attachLazyLoad();

      PubSub.publish("ON_PAGE_CHANGE");

      // remove the class as long as MINIMUM_TRANSITION_TIME as passed since the
      // start
      var end = new Date().getTime();
      var time = end - start;

      if (time < MINIMUM_TRANSITION_TIME) {
        setTimeout(() => {
          body.removeClass(BODY_TRANSISTION_CLASS);
        }, MINIMUM_TRANSITION_TIME - time);
      } else {
        body.removeClass(BODY_TRANSISTION_CLASS);
      }

      // trigger new loaded event.
      window.document.dispatchEvent(
        new Event("DOMContentLoaded", {
          bubbles: true,
          cancelable: true,
        })
      );
    });
  }
  /**
   * Scroll Handler
   */
  function onScroll(evt) {
    // do all your reads...
    requestAnimationFrame(() => {
      if (window.scrollY > 5) {
        body.addClass(SCROLLED_CLASS);
      } else {
        body.removeClass(SCROLLED_CLASS);
      }

      if (lastScrollY > window.scrollY) {
        body.addClass("skr-negative");
      } else if (lastScrollY < window.scrollY) {
        body.removeClass("skr-negative");
      }

      PubSub.publish("ON_PAGE_MOVE");

      lastScrollY = window.scrollY;
    });
  }

  onScroll(); // trigger to update.

  /**
   * Resize handler
   */
  function onResize(evt) {
    lastScrollY = window.scrollY;

    if (lastWidth !== $(window).width()) {
      PubSub.publish("ON_PAGE_MOVE");
      PubSub.publish("ON_PAGE_RESIZE");

      lastWidth = $(window).width();
    }
  }

  window.addEventListener("scroll", onScroll);

  window.addEventListener("popstate", function (e) {
    window.location.reload();
  });

  window.addEventListener("resize", onResize);

  /**
   * Toggle menu button
   */
  PubSub.subscribe("ON_PAGE_READY", function (msg, data) {
    $("body").on("click", ".toggle-menu", function (e) {
      e.preventDefault();

      var open = $("html").hasClass(MENU_OPEN_CLASS);
      console.log("toggled ", open);

      if (open) {
        var previous = $("html").data("scroll-pos");

        $("html, body").animate({
          scrollTop: previous,
        });

        $("html").removeClass(MENU_OPEN_CLASS).removeClass("show-on-page");
      } else {
        // save the current scroll position
        $("html").data("scroll-pos", $(window).scrollTop());

        $("html").addClass(MENU_OPEN_CLASS).removeClass("show-on-page");

        // scroll to the top
        $("html, body").animate({
          scrollTop: 0,
        });
      }

      return false;
    });
  });

  /**
   * Trigger start
   */
  PubSub.subscribe("ON_PAGE_READY", function (e) {
    if (typeof window.Pace !== "undefined") {
      Pace.start();
      Pace.on("done", function () {
        setTimeout(function () {
          $("body").addClass(BODY_LOADED_CLASS);
          PubSub.publish("ON_PACE_DONE");
        }, 500);
      });
    } else {
      setTimeout(function () {
        $("body").addClass(BODY_LOADED_CLASS);
        PubSub.publish("ON_PACE_DONE");
      }, 500);
    }
  });

  /**
   * Trigger operations when page changes via ajax
   */
  PubSub.subscribe("ON_PAGE_CHANGE", function (e) {
    if (typeof initialiseBetterNavigator === "function") {
      initialiseBetterNavigator();
    }

    $("html").removeClass(MENU_OPEN_CLASS);

    if (typeof noCaptchaFieldRender === "function") {
      noCaptchaFieldRender();
    }

    attachLazyLoad();
  });

  $(function () {
    attachPageNavigation();
    attachVideos();
    attachLazyLoad();

    PubSub.publish("ON_PAGE_READY");
  });
})(jQuery);
