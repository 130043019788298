import jQuery from "jquery";
import PubSub from "pubsub-js";

(function ($) {
  function checkInput(input, label) {
    if (input.val().length > 0) {
      label.addClass("freeze");
    } else {
      label.removeClass("freeze");
    }
  }

  function checkInputs() {
    $(".field.text").each(function () {
      const input = $(this).find("input");
      const label = $(this).find("label");

      checkInput(input, label);
    });
  }

  PubSub.subscribe("ON_PAGE_READY", function (e) {
    checkInputs();

    $("body").on("keyup change", ".field.text input", function () {
      const label = $(this).parents(".field.text").find("label");

      checkInput($(this), label);
    });
  });

  PubSub.subscribe("ON_PAGE_CHANGE", function (e) {
    checkInputs();

    $(".field.text").each(function () {
      const input = $(this).find("input");
      const label = $(this).find("label");

      checkInput(input, label);
    });
  });
})(jQuery);
