export const Map = (el) => {
    if (typeof google === "undefined") {
        return;
    }

    if ($("#map").length < 1) {
        return false;
    }

    if ($("#map").hasClass("loaded")) {
        return false;
    }

    $("#map").addClass("loaded");

    const mapCenter = new google.maps.LatLng(-41.2985422, 174.7776168);

    var map = new google.maps.Map(document.getElementById("map"), {
        zoom: 17,
        center: mapCenter,
        styles: mapStyles,
    });

    var infowindow = new google.maps.InfoWindow();

    function placeLocation(loc) {
        let mc = "";

        if (loc.Colour) {
            mc = "-".loc.Colour;
        }

        var marker = new google.maps.Marker({
            position: { lat: loc.Latitude, lng: loc.Longitude },
            map: map,
            title: loc.Title,
            shadow: "/_resources/app/client/dist/img/shadow50.png",
            icon: {
                url: "/_resources/app/client/dist/img/map" + mc + ".png",
                scaledSize: new google.maps.Size(60, 60),
            },
        });

        google.maps.event.addListener(marker, "click", function () {
            infowindow.close();
            infowindow.setContent(
                "<div class='infowindow'><h2>" +
                    loc.Title +
                    "</h2><p>" +
                    loc.Address +
                    "</p><p>" +
                    loc.Description +
                    "</p></div>"
            );
            infowindow.open(map, marker);
        });
    }

    $.each(window.locations, function (i, elem) {
        placeLocation(elem);
    });
};

const mapStyles = [
    {
        featureType: "landscape.natural",
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#f5f5f2",
            },
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "administrative",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "transit",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "poi.attraction",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "landscape.man_made",
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#ffffff",
            },
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "poi.business",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "poi.medical",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "poi.place_of_worship",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "road.arterial",
        stylers: [
            {
                visibility: "simplified",
            },
        ],
    },
    {
        featureType: "poi",
        elementType: "labels.icon",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "water",
        stylers: [
            {
                color: "#71c8d4",
            },
        ],
    },
    {
        featureType: "landscape",
        stylers: [
            {
                color: "#e5e8e7",
            },
        ],
    },
    {
        featureType: "poi.park",
        stylers: [
            {
                color: "#8ba129",
            },
        ],
    },
    {
        featureType: "poi.sports_complex",
        elementType: "geometry",
        stylers: [
            {
                color: "#c7c7c7",
            },
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "water",
        stylers: [
            {
                color: "#a0d3d3",
            },
        ],
    },
    {
        featureType: "poi.park",
        stylers: [
            {
                color: "#91b65d",
            },
        ],
    },
    {
        featureType: "poi.park",
        stylers: [
            {
                gamma: 1.51,
            },
        ],
    },
    {
        featureType: "road.local",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "road.local",
        elementType: "geometry",
        stylers: [
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "poi.government",
        elementType: "geometry",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "landscape",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "road.arterial",
        elementType: "geometry",
        stylers: [
            {
                visibility: "simplified",
            },
        ],
    },
    {
        featureType: "road",
    },
    {
        featureType: "road",
    },
    {},
    {
        featureType: "road.highway",
    },
];
