import jQuery from "jquery";
import "../../../../vendor/willisbondlimited/website-core/client/js/pace";
import "../../../../vendor/willisbondlimited/website-core/client/js/index";
import "../../../../vendor/willisbondlimited/website-core/client/js/components/labels";
import Video from "./Video";
import AOS from "aos";
import { Map } from "./Map";
import "aos/dist/aos.css";
import "../scss/style.scss";

AOS.init({
  duration: 800,
});

document.addEventListener("DOMContentLoaded", function () {
  const $ = jQuery;

  $.subscribe = function (event, callback) {
    $(this).on(event, callback);
  };

  $.publish = function (event, data) {
    $(this).trigger(event, data);
  };

  const updatePageTextures = () => {
    $("[data-green-texture]").each(function () {
      var $this = $(this);
      var $next = $this.next("[data-green-texture]");
      if ($next.length && !$next.parents(".green-texture-wrapper").length) {
        // wrap this and the next node in a div using jquery wrapAll
        $this.add($next).wrapAll('<div class="green-texture-wrapper" />');
      }
    });
  };

  $.subscribe("mapJsLoaded", function (e) {
    Map();
  });

  $("body").removeClass("menu--open");
  updatePageTextures();
  Video();
  Map();

  $("body").on("click", "[data-toggle-video]", function (e) {
    e.preventDefault();

    return false;
  });

  $("body").on(
    "click",
    ".accordion__item-content .accordion__link",
    function (e) {
      e.preventDefault();
      $(this).parents(".accordion__item").toggleClass("accordion__item--open");
    }
  );

  $("body").on("click", ".toggle-menu", function (e) {
    e.preventDefault();
    $("body").toggleClass("menu--open");
  });

  Video();

  $("body").on("click", ".flexslider", function (e) {
    try {
      if ($(".cursor__inner").hasClass("right-arr")) {
        $(this).flexslider("next");
      } else {
        $(this).flexslider("prev");
      }
    } catch (exp) {
      //
    }
    setTimeout(function () {
      $("body").removeClass("navigate-slide");
    }, 250);
  });

  $("body").on("click", ".promotion__close", function (e) {
    e.preventDefault();

    $(this).parents(".promotion").addClass("closed");
    $.get($(this).attr("href"), {}, function () {});

    return false;
  });

  $("body").on("click", ".js-shell-arrow-down", function (e) {
    e.preventDefault();

    // go to the next sibling of the parent .banner
    var $next = $(this).parents(".banner").next("div");

    // smooth scroll to next
    $("html, body").animate(
      {
        scrollTop: $next.offset().top,
      },
      500
    );
  });

  $("body").on(
    "keydown change",
    ".registerinterestblock input, .registerinterestblock textarea, .registerinterestblock select",
    function () {
      var form = $(this).parents("form");

      if (!form || typeof gtag === "undefined") {
        return;
      }

      if (!form.data("ga-started-form")) {
        form.data("ga-started-form", true);

        gtag("event", "started form", {
          event_category: "ROI Form",
        });
      }

      if (!$(this).data("ga-tracked-field")) {
        $(this).data("ga-tracked-field", true);

        var label = $(this).parents(".field").first().find("label").text();

        if (!label) {
          label = $(this).attr("name");
        }

        gtag("event", "filled out field: " + label, {
          event_category: "ROI Form",
        });
      }
    }
  );

  var formSubmitted = false;

  $("body").on("submit", ".registerinterestblock form", function (e) {
    if ($(this).get(0).checkValidity() && !formSubmitted) {
      e.preventDefault();

      var form = $(this);

      var button = form.find(".action");
      button
        .attr("disabled", "disabled")
        .text("Submitting...")
        .addClass("disabled");

      // Creates a timeout to call `submitForm` after one second.
      function submitForm() {
        if (!formSubmitted) {
          formSubmitted = true;
          form.submit();
        }
      }

      if (!form || typeof gtag === "undefined") {
        submitForm();
      } else {
        setTimeout(submitForm, 1000);
      }
    }
  });

  $("body").on("submit", ".plan-form form", function (e) {
    e.preventDefault();

    document.body.classList.remove("wb-modal-open");

    // submit form via $.post
    $.post($(this).attr("action"), $(this).serialize(), function (data) {});
  });
});
