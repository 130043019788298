
const selector = ".video__holder";

/**
 *  Video with custom trigger
 *
 * @param {HTMLElement} el
 */
export const YoutubeVideo = (el) => {
  const playBtn = el.querySelector(".video__trigger");

  const playVideo = (triggerEl) => {
    const { youtubeid } = triggerEl.dataset;

    const onPlayerReady = (playerReadyEv) => {
      playerReadyEv.target.playVideo();
      triggerEl.parentNode.classList.add("video__holder--playing");
    };

    // Create holder for iframe and load player
    const videoHolder = document.createElement("div");
    triggerEl.parentNode.appendChild(videoHolder);

    return new YT.Player(videoHolder, {
      youtubeid,
      playerVars: {
        rel: 0,
      },
      events: {
        onReady: onPlayerReady,
      },
    });
  };

  if (playBtn) {
    playBtn.addEventListener("click", () => {
      playVideo(playBtn);
    });
  }
};

export const VimeoVideo = (el) => {
  const playBtn = el.querySelector(".video__trigger");

  const playVideo = (triggerEl) => {
    const { vimeoid, vimeohash } = triggerEl.dataset;
    const options = {};

    if (vimeohash.length > 0) {
      options.url = `https://player.vimeo.com/video/${vimeoid}?h=${vimeohash}`;
    } else {
      options.id = vimeoid;
    }

    // Create holder for iframe and load player
    const videoHolder = document.createElement("div");
    videoHolder.setAttribute("id", "vimeo-player");

    triggerEl.parentNode.appendChild(videoHolder);

    if (typeof Vimeo !== "undefined") {
      const player = new Vimeo.Player("vimeo-player", options);
      player.play();
    }
  };

  if (playBtn) {
    playBtn.addEventListener("click", () => {
      playVideo(playBtn);
    });
  }
};

export default function init(scope = document) {
  scope.querySelectorAll(selector).forEach((el) => {
    if (el.getAttribute("data-provider") === "Youtube") {
      YoutubeVideo(el);
    } else {
      VimeoVideo(el);
    }
  });
}
